import { Dialog } from '@workos-inc/component-library';
import { ProviderSelection } from 'components/provider-selection';
import { stepsConfig } from 'components/sso/steps/steps-config';
import { StoreContext } from 'components/store-provider';
import { SupportedConnectionType } from 'interfaces/supported-connection-type';
import { useRouter } from 'next/router';
import React, { FC, useContext, useState } from 'react';
import { EventName } from 'utils/constants';
import { graphql } from 'utils/graphql';
import { logError } from 'utils/logger';

export interface SwitchProviderModalProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  trackEvent: (
    event_name: string,
    attributes?: { [key: string]: unknown },
  ) => void;
}

export const SwitchProviderModal: FC<Readonly<SwitchProviderModalProps>> = ({
  isOpen,
  setIsOpen,
  trackEvent,
}) => {
  const router = useRouter();
  const {
    connection: [connection, setConnection],
    domain: [domain, setDomain],
    organization: [organization],
    ssoProvider: [provider, setProvider],
  } = useContext(StoreContext);
  const [switchingProvider, setSwitchingProvider] = useState(false);
  const [selection, setSelection] = useState<
    SupportedConnectionType | undefined
  >(provider);

  const resetModal = () => {
    if (provider !== selection) {
      setSelection(provider);
    }
  };

  const onSwitchConnection = async (): Promise<void> => {
    if (connection && selection && organization) {
      setSwitchingProvider(true);
      const connectionDomain = domain;

      let response;

      try {
        const { id } = connection;

        response = await graphql().CreateConnection({
          name: organization.name,
          type: selection,
          domains: connectionDomain ? [connectionDomain] : [],
        });

        await graphql().DeleteDraftConnection({ id });
      } catch (e) {
        logError(e);
      }

      const data = response?.data?.portal_createConnection;

      if (data) {
        trackEvent(EventName.IdentityProviderSwitched, {
          from_connection_type: provider,
          to_connection_type: selection,
        });

        setDomain(connectionDomain);
        setProvider(selection);
        setConnection(data);
        setIsOpen(false);
        setSwitchingProvider(false);
        void router.replace('/sso/1');
      }
    } else {
      location.reload();
    }
  };

  const submitDisabled = provider === selection || !connection;

  return (
    <Dialog
      acceptText="Switch Identity Provider"
      className="max-w-2xl"
      description="This action will reset any information associated with your connection."
      isAcceptDisabled={submitDisabled || switchingProvider}
      isOpen={isOpen}
      onAccept={onSwitchConnection}
      onOpenChange={(isModalOpen) => {
        resetModal();
        setIsOpen(isModalOpen);
      }}
      title="Are you sure you want to change your Identity Provider?"
    >
      <ProviderSelection
        isSearchable={true}
        onSelection={setSelection}
        providers={Object.keys(stepsConfig) as SupportedConnectionType[]}
        selection={selection}
      />
    </Dialog>
  );
};
